@import url('https://fonts.googleapis.com/css2?family=Ephesis&family=Quicksand:wght@300;600;700&display=swap');
@import "./components/01-Nav/_nav.css";
@import "./components/02-Introduction/_introduction.css";
@import "./components/03-Products/_products.css";
@import "./components/03-Products/SingleProducts/_single-products.css";
@import "./components/04-Gallery/_gallery.css";



body {
  box-sizing: border-box;
  margin: 0;
  overflow-x: hidden;
}