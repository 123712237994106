.glass-effect {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.18);
    border-radius: 32px;
}
.carousel1 {
    background-image: url(../../images/carousel01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    width: 100%;
    height: auto;
    min-height: 450px;
}
.carousel2 {
    background-image: url(../../images/carousel02.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 450px;
}
.carousel3 {
    background-image: url(../../images/carousel03.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    min-height: 450px;
}
.blue {
    color: #5b6fee;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
    
}
h1 {
    color: #8c4e94;
    font-family: "Ephesis", cursive;
}
.textShadow {
    text-shadow: 2px 2px 0px #c9c9c9;
}
.borderColor {
    border: 3px;
    border-color: #8c4e94 !important; 
}
h5 {
    color: #8c4e94;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}
.subtitle {
    position: absolute;
    bottom: 43px;
    right: 150px;
}
.animate-speed {
    --animate-duration: 3.5s;
}