.products-nav {
    height: auto;
    color: #8c4e94;
    font-family: 'Quicksand', sans-serif;
    font-weight: 600;
}
.products-background {
    background-image: url(../../images/hatter2.jpg);
    background-size: cover;
    background-repeat: repeat;

}
.product-list {
    min-height: 730px;
    text-decoration: none;
}

.img-hover-zoom {
    height: auto; 
    overflow: hidden;
    cursor: pointer;
}
.img-hover-zoom img {
    transition: transform 0.5s ease;
}
.img-hover-zoom:hover img {
    transform: scale(1.3);
}
.mellow {
    font-family: 'Quicksand', sans-serif;
    color: #8c4e94;
    border: 4px;
    font-weight: 600;
    text-decoration: none;
}
.color-style {
    border: 2px solid rgb(119, 108, 108);
    width: 40px;
    height: 40px;
}
.color-style:hover {
    box-shadow: none;
    opacity: 0.7;
    
}
.borbott {
    border-bottom: 1px solid #694c61;
}
.MuiTreeItem-label {
    font-size: 1.3rem !important;
}
.product-name {
    display: flex;
    justify-content: left;
    align-items: center;
    min-height: 80px;
    text-align: left;
    font-size: medium;
}
.product-info {
    display: flex;
    align-items: center;
    min-height: 40px;
    padding-bottom: 5px;
}
.product-price {
    text-align: center;
}
.product-piece {
    font-size: 15px;
    text-align: center;
}
.mellowHover {
    display: inline-block;
    transition: background-color 0.3s ease;

}

.mellowHover:hover {
    background-color: #e086ec;
}
@media (max-width: 1400px) {
    .product-price {
        text-align: left;
    }
    .product-piece {
        padding-top: 12px;
    }
    .product-info {
        padding: 8px;
    }
}
@media (max-width: 768px) {
    .product-price {
        text-align: center;
    }
    .product-piece {
        padding-top: 0;
    }
}
@media (max-width: 575px) {
    .product-price {
        text-align: left;
    }
}
