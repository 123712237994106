.navbar {
    z-index: 2;
}
.navbar-title {
    font-size: 1,3rem;
}
@media (max-width: 992px) {
    .navbar-title {
    font-size: 0,5rem;
    text-align: left;
    }
}
.navbar-nav li:hover {
    background-color: #b8b27f;

}
.navbar-nav li a:hover {
    color:pink;
}
.main-nav {
    font-family: blue;
    background-color: #ffffff;
    color: #2c2b2b;
    -webkit-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
    -moz-box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
    box-shadow: 3px 6px 26px 2px rgba(0,0,0,0.65);
}
.logoImage {
    width: 8rem;
    padding: 0;
    margin: 0;
}
.login {
    min-width: 0 !important;
    padding: 0 !important;

}
.cart-container {
    position: static;
}
.total-container {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 316px;
    background-image: url(../../images/hatter2.jpg);
    background-size: cover;
    background-repeat: repeat;
    padding: 10px;
    box-shadow: 0 -4px 4px -2px rgba(0, 0, 0, 0.1); 
}
.logo-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 316px;
    background-image: url(../../images/hatter2.jpg);
    background-size: cover;
    background-repeat: repeat;
    padding: 10px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.1);
}

element.style {
    padding-right: 0 !important;
}

.products-container {
    position: static;
    padding-top: 9.5rem;
    padding-bottom: 4rem;
    display: flex;
    align-items: center;
    

}
.css-1mlxvxp {
    margin-left: 17px !important;
    margin-right: 0 !important;
    overflow:  auto !important;
}
