.pswp__default-caption {
    font-size: 25px !important;
    text-shadow: 2px 2px 2px rgba(0,0,0,0.6) !important;
    color: #5b6fee !important;
    font-weight: 600 !important;
}
.gallery-introduction {
    background-image: url(../../images/hatter2.jpg);
    background-size: cover;
    background-repeat: repeat;
    text-decoration: none;

}
.hover-zoom {
    position: relative;
    height: auto;
    overflow: hidden;
}
.hover-zoom .texty {
    position: absolute;
    bottom: -100%; /* Kezdetben ne legyen látható */
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    color: #5b6fee;
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    transition: bottom 0.5s ease; /* Animáció ideje és stílusa */
}
.hover-zoom:hover .texty {
    bottom: 57%;
    transform: translateY(50%);
    cursor: pointer;
}

.hover-zoom img {
    transition: transform 0.5s ease;
    padding: 2rem;
}

.hover-zoom:hover img {
    transform: scale(1.08);
    cursor: pointer;
    
    
}
.mellow-hover {
    display: inline-block;
    transition: background-color 0.3s ease;
    color: #8c4e94;
    font-weight: bold;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.mellow-hover:hover {
    background-color: #d167df; 
    color: #fff !important;
}
