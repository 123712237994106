.back-button {
    border-radius: 30px;
    background: #ffffff;
    color:#8c4e94;
    border: solid 2px #8c4e94;
    font-weight: 900;
    font-family: inherit;
    text-align: left;
    padding: 0.7em 1.7em ;
    transition: all 0.4s;
    cursor: pointer;
}

.back-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}
.back-button span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 500;
    content: "Vissza";
    color: #ffffff;
    position: absolute;
    opacity: 0;
    top: 0;
    left: 20px;
    transition: 0.7s;
}
.back-button:hover {
    border: solid 2px #cd6bd8;;
    background: #8c4e94;;
    color: #ffffff;
}
.back-button:hover span {
    padding-right: 4em;
}
.back-button:hover span:after {
    opacity: 4;
    right: 0;
}
.cart-button {
    border-radius: 30px;
    background: #ffffff;
    color:#8c4e94;
    border: solid 2px #8c4e94;
    font-weight: 900;
    font-family: inherit;
    text-align: center;
    padding: 0.7em 2.7em ;
    transition: all 0.4s;
    cursor: pointer;
}

.cart-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}
.cart-button span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f07a";
    color: #ffffff;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.7s;
}
.cart-button:hover {
    border: solid 2px #cd6bd8;;
    background: #8c4e94;;
    color: #ffffff;
}
.cart-button:hover span {
    padding-right: 2em;
}
.cart-button:hover span:after {
    opacity: 4;
    right: 0;
}
.buy-button {
    border-radius: 30px;
    background: #ffffff;
    color:#8c4e94;
    border: solid 2px #8c4e94;
    font-weight: 900;
    font-family: inherit;
    text-align: center;
    padding: 0.7em 2.7em ;
    transition: all 0.4s;
    cursor: pointer;
}

.buy-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}
.buy-button span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f09d";
    color: #ffffff;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.7s;
}
.buy-button:hover {
    border: solid 2px #cd6bd8;;
    background: #8c4e94;;
    color: #ffffff;
}
.buy-button:hover span {
    padding-right: 2em;
}
.buy-button:hover span:after {
    opacity: 4;
    right: 0;
}
.contact-button {
    border-radius: 30px;
    background: #ffffff;
    color:#8c4e94;
    border: solid 2px #8c4e94;
    font-weight: 900;
    font-family: inherit;
    text-align: center;
    padding: 0.7em 2.7em ;
    transition: all 0.4s;
    cursor: pointer;
}

.contact-button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
}
.contact-button span:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f0e0";
    color: #ffffff;
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.7s;
}
.contact-button:hover {
    border: solid 2px #cd6bd8;;
    background: #8c4e94;;
    color: #ffffff;
}
.contact-button:hover span {
    padding-right: 2em;
}
.contact-button:hover span:after {
    opacity: 4;
    right: 0;
}
.input-number {
    width: 70px;
    padding: 0 12px;
    vertical-align: top;
    text-align: center;
    outline: none;
}
.input-number,
.input-number-decrement,
.input-number-increment {
    border: 1px solid #ccc;
    height: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.input-number-decrement,
.input-number-increment {
    display: inline-block;
    width: 30px;
    line-height: 38px;
    background: #f1f1f1;
    color: #444;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
}
.input-number-increment:active {
    border: none;
    background: #aab8f5;
}
.input-number-decrement:active {
    border: none;
    background: #aab8f5;
}
.input-number-decrement {
    border-right: none;
    border-radius: 40px 0 0 40px;
}
.input-number-increment {
    border-left: none;
    border-radius: 0 40px 40px 0;
}
.termekleiras {
    background-color: #ffffff;
    color:black;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
    -webkit-box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
    -moz-box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
}
.discountHandler {
    font-size: 1.8rem;
    padding-left: 6rem;
}
@media (max-width: 1600px) {
    .discountHandler {
        font-size: 1.5rem;
        padding-bottom: 0.5rem;
        padding-left: 5rem;
    }
}
@media (max-width: 1399px) {
    .discountHandler {
        font-size: 1.8rem;
        padding-bottom: 0.3;
        padding-left: 5rem;
    }
}
@media (max-width: 1100px) {
    .discountHandler {
        font-size: 1.3rem;
        padding-bottom: 0.3;
        padding-left: 5rem;
    }
}
@media (max-width: 768px) {
    .discountHandler {
        font-size: 1.8rem;
        padding-bottom: 0.3;
        padding-left: 5rem;
    }
}
@media (max-width: 576px) {
    .discountHandler {
        font-size: 1.3rem;
        padding-bottom: 0.3;
        padding-left: 5rem;
    }
}

.order-main {
    background-color: #ffffff;
    color:#8c4e94;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    border-radius: 10px;
    box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
    -webkit-box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
    -moz-box-shadow: 9px 8px 43px 1px rgba(0,0,0,0.61);
}
img.hov {
    border: 2px solid #fee7d5;
}
img.hov:hover {
    border: 2px solid #8c4e94;
    cursor: pointer;
}


